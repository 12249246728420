import { Controller } from "stimulus"
import showToast from "lib/show_toast";

const SYNC_ERROR = "Błąd synchronizacji - sprawdź połączenie z internetem";

export default class extends Controller {
  static targets = [ "checkbox", "allCheckbox" ]
  static values = {
    type: String,
    cancelled: Boolean
  }

  connect() {
    this.dispatchRowStatus()
  }

  hoverStart() {
    this.checkboxTargets.forEach(element => {
      element.classList.add("border-blue-500")
    });
  }

  hoverEnd() {
    this.checkboxTargets.forEach(element => {
      element.classList.remove("border-blue-500")
    });
  }

  async submitForm(form) {
    const checkbox = form.querySelector("input[id]")
    try {
      this.dispatchFulfilled(checkbox)
      await fetch(form.action, {
        redirect: 'manual',
        method: form.method,
        body: new FormData(form)
      })
    } catch {
      checkbox.checked = !checkbox.checked
      this.dispatchFulfilled(checkbox)
      this.dispatchRowStatus()
      this.updateAllStatus()
      showToast({message: SYNC_ERROR, success: false})
    }
  }

  dispatchRowStatus() {
    if (this.checkboxTargets.every(e => e.checked)) {
      this.dispatch("addClass", { detail: this.typeValue })
    } else {
      this.dispatch("removeClass", { detail: this.typeValue })
    }
  }

  dispatchFulfilled({ checked, dataset: { id, quantity } }) {
    if (!this.cancelledValue) {
      this.dispatch("change", {
        prefix: this.typeValue,
        detail: { id, quantity: checked ? Number(quantity) : -Number(quantity) }
      })
    }
  }

  updateAllStatus() {
    if (!this.hasAllCheckboxTarget) { return }

    if (this.checkboxTargets.every(e => e.checked)) {
      this.allCheckboxTarget.checked = true
    } else {
      this.allCheckboxTarget.checked = false
    }
  }

  update(event) {
    this.submitForm(event.target.form)
    this.updateAllStatus()
    this.dispatchRowStatus()
  }

  updateAll(event) {
    this.checkboxTargets.forEach(element => {
      element.checked = event.target.checked
      this.submitForm(element.form)
    });
    this.dispatchRowStatus()
  }
}
