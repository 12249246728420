import Toastify from 'toastify-js'
import colors from 'tailwindcss/colors'

const showToast = ({message, success}) => {
  Toastify({
    text: message,
    close: true,
    style: { background: success ? colors.green[500] : colors.red[500] },
    duration: 3000
  }).showToast()
}

export default showToast