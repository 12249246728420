import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "counter" ]
  static classes = [ "all" ]
  static values = {
    id: String
  }

  update({ detail: { id, quantity }}) {
    if (id == this.idValue) {
      const total = Number(this.counterTarget.innerHTML) - quantity;
      if (total === 0) {
        this.element.classList.add(this.allClass)
      } else {
        this.element.classList.remove(this.allClass)
      }
      this.counterTarget.innerHTML = total;
    }
  }
}
