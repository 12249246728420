import { Controller } from "stimulus"

export default class extends Controller {
  addClass(event) {
    this.element.classList.add(event.detail);
  }

  removeClass(event) {
    this.element.classList.remove(event.detail);
  }
}